<template>
  <div class="identitydata">
    <van-nav-bar
      :title="$t('IDENTITYDATA')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <!-- <div class="tips">{{$t('FILLINTRUEINFORMATION')}}</div> -->
    <div class="container">
      <van-field :readonly="stopSub" v-model="name" :placeholder="$t('PLEAINPUTNAME')" label="ชื่อ-นามสกุล" />
      <van-field :readonly="stopSub" v-model="IDcardNumber" :placeholder="$t('PLEAINPUTID')" type="number" :label="$t('IDCARDNUMBER')" />
    </div>
    <div class="tips">*{{$t('UPLOADIDPROTO')}}</div>
    <!-- 文件上传一 -->
    <div class="IDUploadbox">
      <van-uploader v-if="AUploader" class="Uploader" :after-read="afterReadPositive">
        <div class="UploadArea">
          <van-image class="cameraImg" :src="imgA" />
        </div>
      </van-uploader>
      <div v-else class="showimg">
        <div class="del" @click="removeA">X</div>
        <img @click="click_img_positive" class="img" :src="imgUrlA" >
      </div>
    </div>
    <!-- 文件上传二 -->
    <div class="IDUploadbox">
      <van-uploader v-if="BUploader"  class="Uploader" :after-read="afterReadOnTheBack" >
        <div class="UploadArea">
           <van-image class="cameraImg" :src="imgB" />
        </div>
      </van-uploader>
      <div v-else class="showimg">
        <div class="del" @click="removeB">X</div>
        <img @click="click_img_reverse" class="img" :src="imgUrlB">
      </div>
    </div>
    <!-- 文件上传三 -->
    <div class="IDUploadbox">
      <van-uploader v-if="CUploader" class="Uploader" :after-read="afterReadHand">
        <div class="UploadArea">
           <van-image class="cameraImg" :src="imgC" />
        </div>
      </van-uploader>
      <div v-else class="showimg">
        <div class="del" @click="removeC">X</div>
        <img @click="click_img_handheld" class="img" :src="imgUrlC" >
      </div>
    </div>
    <div class="subbox">
      <van-button :disabled="stopSub" @click="sub" class="sub" color="#FF910B" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import { get, save, myInformation } from '@/api/my'
import { uploadPhoto } from '@/api/wallet'
import { Toast, Dialog, ImagePreview } from 'vant'
import './css/my.css'
export default {
  name: 'identitydata',
  data () {
    return {
      name: null,
      IDcardNumber: null,
      // showdis: false,
      AUploader: true,
      BUploader: true,
      CUploader: true,
      imgUrlA: '',
      imgUrlB: '',
      imgUrlC: '',
      stopSub: false,
      imgA: require('./img/zhengmian.png'),
      imgB: require('./img/contrary.png'),
      imgC: require('./img/handtake.png')
    }
  },
  created () {
    this.loadget()
    this.getmyInformation()
  },
  methods: {
    removeA () {
      this.AUploader = true
      this.imgUrlA = null
    },
    removeB () {
      this.BUploader = true
      this.imgUrlB = null
    },
    removeC () {
      this.CUploader = true
      this.imgUrlC = null
    },
    getMainHost () {
      const key = `mh_${Math.random()}`
      const keyR = new RegExp(`(^|;)\\s*${key}=12345`)
      const expiredTime = new Date(0)
      const domain = document.domain
      const domainList = domain.split('.')

      const urlItems = []
      // 主域名一定会有两部分组成
      urlItems.unshift(domainList.pop())
      // 慢慢从后往前测试
      while (domainList.length) {
        urlItems.unshift(domainList.pop())
        const mainHost = urlItems.join('.')
        const cookie = `${key}=${12345};domain=.${mainHost}`

        document.cookie = cookie

        // 如果cookie存在，则说明域名合法
        if (keyR.test(document.cookie)) {
          document.cookie = `${cookie};expires=${expiredTime}`
          return mainHost
        }
      }
    },
    async loadget () {
      const { data } = await get()
      if (data.code === 200) {
        this.name = data.data.name
        this.IDcardNumber = data.data.idCardNo
        this.showdis = true
        this.imgUrlA = data.data.idFrontUrl
        this.imgUrlB = data.data.idBackUrl
        this.imgUrlC = data.data.idPanoramaUrl
        if (this.imgUrlA) {
          this.AUploader = false
        }
        if (this.imgUrlB) {
          this.BUploader = false
        }
        if (this.imgUrlC) {
          this.CUploader = false
        }
      }
      // console.log(data)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    async afterReadPositive (file) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      if (data.code === 200) {
        this.imgUrlA = `https://file.${this.getMainHost()}/${data.data}`
        this.AUploader = false
        // console.log(this.imgUrlA)
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async afterReadOnTheBack (file) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      if (data.code === 200) {
        this.imgUrlB = `https://file.${this.getMainHost()}/${data.data}`
        this.BUploader = false
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async afterReadHand (file) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      if (data.code === 200) {
        this.imgUrlC = `https://file.${this.getMainHost()}/${data.data}`
        this.CUploader = false
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async sub () {
      if (this.imgUrlB && this.IDcardNumber && this.imgUrlA && this.name && this.imgUrlC) {
        const { data } = await save({
          idBackUrl: this.imgUrlB,
          idCardNo: this.IDcardNumber,
          idFrontUrl: this.imgUrlA,
          name: this.name,
          idPanoramaUrl: this.imgUrlC
        })
        if (data.code === 200) {
          this.stopSub = true
          Dialog.alert({
            message: this.$t('SUBMITSUCCEED'),
            confirmButtonText: this.$t('CONFIRM')
          }).then(() => {
            // on close
          })
          // Toast(data.message)
          setTimeout(() => {
            this.$router.go(-1)
          }, 3000)
        }
        if (data.code === 500) {
          Dialog.alert({
            message: data.message,
            confirmButtonText: this.$t('CONFIRM')
          }).then(() => {
            // on close
          })
        }
      } else {
        Toast(this.$t('FILLINALL'))
      }
    },
    async getmyInformation () {
      const { data } = await myInformation()
      if (data.data.identity) {
        // console.log(data.data.identity)
        this.stopSub = true
      } else {
        // console.log(false)
        this.stopSub = false
      }
      // console.log(data)
    },
    // 正面图片预览
    click_img_positive () {
      if (this.imgUrlA === '') return false
      ImagePreview([this.imgUrlA])
    },
    // 反面图片预览
    click_img_reverse () {
      if (this.imgUrlB === '') return false
      ImagePreview([this.imgUrlB])
    },
    // 手持图片预览
    click_img_handheld () {
      if (this.imgUrlC === '') return false
      ImagePreview([this.imgUrlC])
    }
  }
}
</script>

<style scoped>
.identitydata >>> .van-nav-bar__content {
  background-color: #FF910B;
  /* color: #fff; */
}
.identitydata >>> .van-nav-bar__title {
  color: #fff;
}
.img {
  width: 100%;
  height: 100%;
}
.del {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 50px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  text-indent: .5em;
}
.showimg {
  position: relative;
  width: 100%;
  height: 100%;
}
.tips {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #666;
}
.container {
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}
.IDUploadbox {
  box-sizing: border-box;
  padding: 3px;
  height: 200px;
  background-color: #fff;
}
.UploadArea {
  width: 367px;
  height: 190px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}
.cameraBox {
  margin: 0 auto;
  margin-top: 40px;
  width: 70px;
  height: 70px;
}
.cameraImg {
  width: 100%;
  height: 100%;
}
.UploadInstructions {
  margin: 0 auto;
  width: 150px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-size: 14px;
  background: #9cf;
  opacity: .5;
  padding: 0 9px;
  margin-top: 20px;
  border-radius: 8px;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
</style>
